import { makeStyles } from '@material-ui/core/styles';
import {
  Backdrop as BackdropMUI,
  CircularProgress as Progress,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

type Props = {
  status: string;
}

export default function Loading({
  status
}: Props) {
  const classes = useStyles();

  return (
    <BackdropMUI className={classes.backdrop} open>
      <Typography variant="h6">{`${status} ...`}</Typography>
      <Progress color="inherit" />
    </BackdropMUI>
  );
}
