import axios from "axios";
import React from "react";
import { TokenType } from "../types";
import { apiHostname } from "../config";
import Loading from "../components/Loading";
import { useNavigate, useSearchParams } from "react-router-dom";

type AxiosRes<T> = {
  status: number;
  statusText: string;
  data: T;
};

const getAccessToken = (code: string): Promise<AxiosRes<TokenType>> => {
  return axios.post(`${apiHostname}api/v1/i/token/access-token`, {
    authCode: code,
  });
};

export default function Callback(): JSX.Element {
  const [searchParams] = useSearchParams();
  const nav = useNavigate();

  const init = async () => {
    const code = searchParams.get("code");
    if (code !== null) {
      try {
        const res = await getAccessToken(code);
        sessionStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
      } catch {
        sessionStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
      } finally {
        nav("/");
      }
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  return <Loading status="Xác thực người dùng" />;
}
