import React from "react";
import { AppRender } from "@ecoba-vn/client-core";
import {
  Home,
  Pip,
} from '@ecoba-vn/pip-feature'

// import { apiHostname } from "./config";
// import Loading from "./components/Loading";

const features = [
  Home,
  Pip,
];

// type UserInfoRes = {
//   employeeId: string;
//   displayName: string;
// };

export default function Content() {
  // const { setAppUser } = React.useContext(AppContext);
  // const [isLoading, setIsLoading] = React.useState<boolean>(true);

  // const getAppUser = async () => {
  //   setIsLoading(true);
  //   try {
  //     const instance = axiosBuilder.getInstance(apiHostname);
  //     const res = await instance.get("/api/v1/i/account/me");
  //     const data = res.data as UserInfoRes;
  //     setAppUser({
  //       email: data.displayName,
  //       fullName: data.displayName,
  //       number: data.employeeId,
  //     });
  //   } catch {
  //     setAppUser(null);
  //   }

  //   setIsLoading(false);
  // };

  React.useEffect(() => {
    // getAppUser();
  }, []);

  // return isLoading ? (
  //   <Loading status="Lấy thông tin người dùng" />
  // ) : (
  //   <AppRender features={features} />
  // );

  return <AppRender features={features} />
}
