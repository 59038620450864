import ReactDOM from "react-dom";
import { AppProvider } from "@ecoba-vn/client-core";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import Callback from "./pages/Callback";
// import "react-quill/dist/quill.snow.css";

ReactDOM.render(
  <BrowserRouter>
    <AppProvider>
      <Routes>
        <Route path="*">
          <Route path="callback" element={<Callback />} />
          <Route path="*" element={<App />} />
        </Route>
      </Routes>
    </AppProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
serviceWorkerRegistration.register();

reportWebVitals();
