import { DefaultTheme } from "@ecoba-vn/client-themes";

import Content from "./Content";
// import Login from "./pages/Login";

function App() {
  // const refresh_token = localStorage.getItem("refresh_token");
  return (
    <div className="App">
      {/* {refresh_token === null ? (
        <Login />
      ) : ( */}
        <DefaultTheme>
          <Content />
        </DefaultTheme>
      {/* )} */}
    </div>
  );
}

export default App;
